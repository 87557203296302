.LocationModal > .Centered-location-modal > .modal-dialog {
  max-width: 800px !important;
}
.LocationModal > .line {
  flex: 1;
  border-bottom: 1px solid #EBEBEB; /* Adjust the style of the line as needed */
  margin: 0 10px;
}
.LocationModal > .or {
  text-align: center;
  color: #747474;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.line {
  flex: 1;
  border-bottom: 1px solid #EBEBEB; /* Adjust the style of the line as needed */
  margin: 0 10px;
}

.or {
  text-align: center;
  color: #747474;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}/*# sourceMappingURL=LocationModal.css.map */