.PlanDetails--Header {
    @media only screen and (max-width: 600px) {
        flex-direction: column-reverse !important;
        padding: 20px;
        gap: 0 !important;
    }
    .Header--Left {
        flex: 1;
        flex-direction: column;
        display: flex;
        /* align-items: center; */
        justify-content: center;
        padding: 2rem;
        max-width: 740px;
        margin: auto;
        @media only screen and (max-width: 600px) {
            padding: 20px 0 !important;
        }
        h2 {
            font-family: Roboto;
            font-size: 85px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 62.118% */
            letter-spacing: 0.48px;
            background: linear-gradient(92deg, #701a75 36.33%, #0f172a 91.14%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media only screen and (max-width: 600px) {
                font-size: 26px;
                line-height: 21.738px; /* 83.609% */
                letter-spacing: 0.198px;
            }
        }
        h6 {
            font-family: Roboto;
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 68.699px;
            @media only screen and (max-width: 600px) {
                leading-trim: both;
                text-edge: cap;
                font-size: 16px;
                line-height: 28.284px; /* 176.776% */
            }
        }

        .PackOffers {
            display: flex;
            flex-direction: column;
            gap: 18px;
            margin-top: 50px;
            @media only screen and (max-width: 600px) {
                margin-top: 0px;
            }

            .Offers--Item {
                cursor: pointer;
                display: flex;
                gap: 10px;
                align-items: flex-start;
                .Item--Number {
                    border-radius: 36px;
                    display: flex;
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    padding: 6px 12.4px 8px 12.6px;
                    flex-direction: column;
                    align-items: center;
                    flex-shrink: 0;
                    border: 1px solid #000;
                }

                .Item--Desc {
                    padding-top: 5px;
                    p {
                        color: #000;
                        font-family: Roboto;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26.4px; /* 110% */
                        letter-spacing: 0.24px;
                        @media only screen and (max-width: 600px) {
                            font-size: 15px;
                            line-height: 120%; /* 72.461% */
                            letter-spacing: 0.099px;
                        }
                        span {
                            color: #541cf5;
                        }
                    }
                    & > span {
                        color: #000;
                        font-family: Roboto;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28.8px; /* 160% */
                        letter-spacing: 0.54px;
                        @media only screen and (max-width: 600px) {
                            font-size: 12px;
                            line-height: 130%; /* 15.6px */
                            letter-spacing: 0.222px;
                        }
                    }
                }
            }

            .SelectedItem {
                .Item--Number {
                    background: #541cf5;
                    color: #fff;
                    border-color: #541cf5;
                }
            }
        }

        button {
            display: flex;
            padding: 18.844px 71.7px 18.844px 65.953px;
            justify-content: center;
            align-items: center;
            border-radius: 40px;
            background: #f25d0f;
            border: none;
            width: max-content;
            margin-top: 18px;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 28.266px; /* 128.48% */
            @media only screen and (max-width: 600px) {
                padding: 15.567px 54px 15.567px 54.484px;
                font-size: 18.174px;
                line-height: 23.35px; /* 128.48% */
                margin: 50px auto 0 auto;
            }
        }
    }
    img {
        width: 50%;
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
}
.PlanDetails--Header.Platinum {
    h2 {
        background: linear-gradient(92deg, #6d7ae8 14.3%, #131313 81.94%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.PlanDetails--Header.RTL {
    flex-direction: row-reverse;
}

.Stats--Area {
    z-index: 1;
    position: relative;
    //max-width: 1550px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 80px;
    gap: 38px;
    overflow-x: hidden;
    & > div {
        max-width: 1550px;
        margin: auto;
        @media only screen and (max-width: 600px) {
            gap: 30px !important;
            display: grid !important;
            grid-template-columns: 1fr 1fr;
            align-items: baseline !important;
        }
    }
    @media only screen and (max-width: 600px) {
        padding: 30px;
        // display: grid !important;
        // grid-template-columns: 1fr 1fr;
        // gap: 25px;
    }
    .Stat--Item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        // flex: 1;

        @media only screen and (max-width: 600px) {
            gap: 7px;
        }
        h6 {
            //color: #541CF5;
            font-family: "Times New Roman";
            font-size: 85px;
            font-style: normal;
            font-weight: 700;
            line-height: 81.032px; /* 95.332% */
            letter-spacing: 1.473px;
            display: flex;
            align-items: center;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 600px) {
                font-size: 33.064px;
                font-style: normal;
                font-weight: 700;
                line-height: 31.521px; /* 95.332% */
                letter-spacing: 0.573px;
            }
        }

        > div {
            gap: 10px;
            div {
                @media only screen and (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                }
            }
            svg {
                @media only screen and (max-width: 600px) {
                    width: 25px;
                    height: 25px;
                }
            }
            p {
                @media only screen and (max-width: 600px) {
                    font-family: Plus Jakarta Sans;
                    font-size: 12.274px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16.365px; /* 133.333% */
                }
            }

            span {
                @media only screen and (max-width: 600px) {
                    font-size: 6.137px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 8.183px; /* 133.333% */
                }
            }
        }

        p {
            color: #000;
            font-family: Roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 31.362px; /* 127.722% */
            letter-spacing: 0.737px;
            @media only screen and (max-width: 600px) {
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 12.2px; /* 110.907% */
                letter-spacing: 0.287px;
            }
        }

        &:nth-child(1) {
            h6 {
                color: #20af7c;
            }
        }
        &:nth-child(2) {
            h6 {
                color: #ce0058;
            }
        }
        &:nth-child(3) {
            h6 {
                color: #541cf5;
            }
        }
        &:nth-child(4) {
            h6 {
                color: #a601a9;
            }
        }
    }
}

.Carousel--Area {
    position: relative;
    background-color: #f4f4f4;
    padding: 50px;
    margin-top: -300px;
    z-index: 0;
    .Swiper--Area {
        position: relative;
        & > img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .Img--Container {
        width: 100%;
        max-width: 500px;
        img {
            max-width: 500px;
            @media only screen and (max-width: 600px) {
                max-width: 250px;
            }
        }
    }
    @media only screen and (max-width: 600px) {
        padding: 0;
        margin-top: -160px;
    }
    .Carousel--Top {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        @media only screen and (max-width: 600px) {
            margin-bottom: 10px;
        }
        div {
            width: 600px;
            height: 550px;
            border-radius: 50%;
            background-color: #541cf5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
            margin-right: auto;
            gap: 33px;
            @media only screen and (max-width: 600px) {
                width: 300px;
                height: 300px;
                gap: 5px;
            }

            h4 {
                color: #f4f4f4;
                font-family: Roboto;
                font-size: 134.164px;
                font-style: normal;
                font-weight: 600;
                line-height: 95.763px; /* 62.118% */
                letter-spacing: 0.871px;
                @media only screen and (max-width: 600px) {
                    font-size: 63.111px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 38px; /* 60.211% */
                    letter-spacing: 0.356px;
                }
            }
            h6 {
                color: #fff;
                font-family: Roboto;
                font-size: 47.637px;
                font-style: normal;
                font-weight: 400;
                line-height: 29.591px; /* 62.118% */
                letter-spacing: 0.269px;
                padding-bottom: 70px;
                @media only screen and (max-width: 600px) {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 38px;
                    letter-spacing: 0.356px;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .Psycologist--List {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 30px 10px;
        @media only screen and (max-width: 600px) {
            gap: 11px 5px;
            justify-content: center;
            padding: 18px;
        }

        span {
            padding: 2px 10px;
            border-radius: 16px;
            @media only screen and (max-width: 600px) {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
            }
        }
    }
    .Card--Container {
        width: 100%;
        flex: 1;
        max-width: 800px;
        .card {
            width: 100%;
            max-width: 500px;
            margin: auto;
            border-radius: 15px;
            @media only screen and (max-width: 600px) {
                padding: 7px !important;
                max-width: 240px;
                margin-top: 15px;
                // &:nth-child(4),
                // &:nth-child(3) {
                //     display: none;
                // }
            }
            .MuiCardMedia-media {
                aspect-ratio: 3/2;
                height: auto;
                border-radius: 12px;
            }
            .MuiCardContent-root {
                padding: 16px 0;
            }

            .MuiTypography-h5 {
                color: #101828;
                font-family: Inter;
                font-size: 30.613px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 45.92px */
                @media only screen and (max-width: 600px) {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; /* 11.166px */
                }
            }
            .card--options {
                max-width: 600px;
                @media only screen and (max-width: 600px) {
                    flex-wrap: wrap;
                }
                & > span {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    border-radius: 30px;
                    background: rgba(159, 214, 139, 0.1);
                    padding: 12px 20px;
                    color: #4c9234;
                    text-align: center;
                    font-family: Inter;
                    font-size: 22.96px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25.511px; /* 111.111% */

                    line-height: 14.397px; /* 111.111% */
                    width: max-width;
                    justify-content: center;
                    @media only screen and (max-width: 600px) {
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 9.422px; /* 111.111% */
                        padding: 4.711px 7.538px;
                        gap: 5px;
                    }

                    svg {
                        @media only screen and (max-width: 600px) {
                            width: 12px;
                        }
                    }
                }
                .whatsapp--btn {
                    @media only screen and (max-width: 600px) {
                        padding: 4.711px !important;
                    }

                    img {
                        @media only screen and (max-width: 600px) {
                            width: 15px;
                        }
                    }
                }
                button {
                    gap: 10px;
                    border-radius: 50px !important;
                    border: 0.72px solid #d0d5dd;
                    background: #fff;
                    box-shadow: 0px 0.71983px 1.43967px 0px rgba(16, 24, 40, 0.05);
                    min-width: max-content;
                    padding: 12px 20px;
                    border-radius: 20px;
                    color: #344054;
                    font-family: Inter;
                    font-size: 17.858px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 25.511px; /* 142.857% */
                    text-transform: capitalize;
                    @media only screen and (max-width: 600px) {
                        gap: 5px;
                        font-size: 10px;
                        font-style: normal;
                        // font-weight: 500;
                        line-height: 9.422px; /* 94.222% */
                        padding: 4.711px 7.538px;
                    }
                    svg {
                        @media only screen and (max-width: 600px) {
                            width: 12px;
                            height: auto;
                        }
                    }
                }
            }

            .MuiCardActions-root {
                button {
                    margin: auto;
                    display: flex;
                    padding: 10px 33px;
                    justify-content: center;
                    align-items: center;
                    gap: 7.198px;
                    border-radius: 50px;
                    background: #fd6f1f;
                    color: #fff;
                    text-align: center;
                    font-family: Inter;
                    font-size: 22.96px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28.172px; /* 122.699% */
                    text-transform: capitalize;
                    @media only screen and (max-width: 600px) {
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%; /* 111.111% */
                    }
                }
            }
        }
    }
    .Desc--Area {
        color: #fff;
        text-align: left;
        flex: 1;
        padding-right: 50px;
        @media only screen and (max-width: 600px) {
            padding-right: 0;
        }
        h6 {
            color: #fff;
            font-family: Roboto;
            font-size: 45.714px;
            font-style: normal;
            font-weight: 400;
            line-height: 131.5%; /* 60.114px */
            letter-spacing: 0.457px;
            padding: 30px 0;
            @media only screen and (max-width: 600px) {
                font-size: 23.146px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.231px;
                padding: 30px 0 5px;
            }
            span {
                background: linear-gradient(92deg, #03ff77 6.75%, #01b152 41.39%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: Roboto;
                font-size: 45.714px;
                font-style: normal;
                font-weight: 500;
                line-height: 131.5%;
                letter-spacing: 0.457px;
            }
        }
        & > button {
            display: flex;
            padding: 20px 30px;
            align-items: flex-start;
            text-transform: capitalize;
            margin-top: 30px;
            width: max-content;
            border-radius: 60px;
            background: #f25d0f;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px; /* 100% */
            letter-spacing: 0.662px;
            @media only screen and (max-width: 600px) {
                font-size: 16.219px;
                font-style: normal;
                font-weight: 700;
                line-height: 16.219px; /* 100% */
                letter-spacing: 0.488px;
                padding: 14.745px 22.117px;
                margin: 30px auto;
            }
        }
        ul {
            @media only screen and (max-width: 600px) {
                padding-left: 12px;
            }
        }
        li {
            color: #fff;
            font-family: Roboto;
            font-size: 22.067px;
            font-style: normal;
            font-weight: 400;
            line-height: 40.29px; /* 182.583% */
            letter-spacing: 0.662px;
            margin-bottom: 12px;
            span {
                color: #f5d21c;
                font-size: 30px;
                @media only screen and (max-width: 600px) {
                    font-size: 18px;
                }
            }

            b {
                font-size: 30px;
                @media only screen and (max-width: 600px) {
                    font-size: 18px;
                }
            }
            @media only screen and (max-width: 600px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.4px; /* 145.712% */
                letter-spacing: 0.335px;
                margin-bottom: 10px;
            }
        }
        .Courses--List {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 30px 10px;
            @media only screen and (max-width: 600px) {
                gap: 11px 5px;
                padding-top: 10px;
            }

            span {
                padding: 2px 10px;
                border-radius: 16px;
                @media only screen and (max-width: 600px) {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }
    }
    .swiper {
        width: 100%;
        min-height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: transparent;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 100px;

        @media only screen and (max-width: 600px) {
            padding: 20px;
        }

        & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            @media only screen and (max-width: 600px) {
                height: 110%;
            }
        }
    }

    .swiper--content {
        display: flex;
        align-items: center;
        gap: 50px;
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            gap: 25px;
        }
    }

    .swiper {
        margin-left: auto;
        margin-right: auto;
    }
    .swiper-button-prev {
        @media only screen and (max-width: 600px) {
            background-image: url("../images/arrow-left.svg");
            background-size: contain;
            background-position-x: center;
            background-repeat: no-repeat;
        }
    }

    .swiper-button-next {
        @media only screen and (max-width: 600px) {
            background-image: url("../images/arrow-right.svg");
            background-size: contain;
            background-position-x: center;
            background-repeat: no-repeat;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 50px;
        height: 50px;
        background-color: #000;
        border-radius: 50%;
        @media only screen and (max-width: 600px) {
            display: flex !important;
            top: 17%;
            width: 36px;
            height: 36px;
            background-color: #fff;
        }
        &::after {
            font-size: 1.5rem;
            color: #fff;
            @media only screen and (max-width: 600px) {
                content: "";
                color: #000;
            }
        }
    }
    // .swiper-button-prev:after,
    // .swiper-button-next:after {
    //     color: #fff;
    //     background-color: #000;
    //     border-radius: 50%;
    // }
    .swiper-wrapper {
        align-items: center;
    }
}

.WhyTrustUs--Area {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 100px;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
        padding: 20px;
    }
    .WhyTrustUs--Top {
        display: flex;
        align-items: center;
        gap: 100px;
        max-width: 1650px;
        margin: auto;
        padding: 100px 50px;
        position: relative;
        z-index: 1;
        margin-bottom: 200px;
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            width: 100%;
            padding: 10px 0px 140px;
            margin-bottom: 0;
            gap: 50px;
        }
        .Desc--Area {
            width: 600px;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
            h4 {
                color: #000;
                font-family: Roboto;
                font-size: 85px;
                font-style: normal;
                font-weight: 600;
                line-height: 110%; /* 93.5px */
                @media only screen and (max-width: 600px) {
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 110%;
                    text-align: center;
                }

                span {
                    background: linear-gradient(112deg, #00af66 35.63%, #048851 58.67%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-family: Roboto;
                    font-size: 85px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                    @media only screen and (max-width: 600px) {
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 110%;
                    }
                }
            }
            p {
                color: #000;
                font-family: Roboto;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 48px */

                @media only screen and (max-width: 600px) {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                }
            }
        }

        .Images--Area {
            flex: 1;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 19px;
            @media only screen and (max-width: 600px) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 1px;
            }

            img {
                &:nth-child(1) {
                    position: relative;
                    top: 97px;
                    @media only screen and (max-width: 600px) {
                        top: 40px;
                    }
                }
                &:nth-child(3) {
                    position: relative;
                    top: 48px;
                    @media only screen and (max-width: 600px) {
                        top: 20px;
                    }
                }
            }
        }
    }
    .WhyTrustUs--Bottom {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 100px 50px;
        max-width: 1650px;
        width: 100%;
        margin: auto;
        @media only screen and (max-width: 600px) {
            padding: 0;
            flex-direction: column;
        }
        .Desc--Area {
            width: 1000px;
            position: relative;
            z-index: 1;
            @media only screen and (max-width: 600px) {
                width: 100%;
                text-align: center;
            }
            h4 {
                color: #fffbeb;
                font-family: Roboto;
                font-size: 128.733px;
                font-style: normal;
                font-weight: 800;
                line-height: 110%; /* 141.607px */
                @media only screen and (max-width: 600px) {
                    font-size: 51.493px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 110%; /* 56.643px */
                }
            }
            h6 {
                color: #fffbeb;
                font-family: Roboto;
                font-size: 60.094px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%; /* 82.604px */
                @media only screen and (max-width: 600px) {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%; /* 30.8px */
                }
            }
            button {
                margin-top: 50px;
                width: max-content;
                display: flex;
                padding: 18.844px 71.7px 18.844px 65.953px;
                justify-content: center;
                align-items: center;
                border-radius: 30px;
                background: #f25d0f;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 28.266px; /* 128.48% */
                border: none;

                @media only screen and (max-width: 600px) {
                    padding: 11.918px 45.968px;
                    font-size: 15.323px;
                    margin: 30px auto;
                }
            }
        }
        & > img {
            display: none;
            @media only screen and (max-width: 600px) {
                display: block;
                position: relative;
                object-fit: cover;
            }
        }
        picture {
            img {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 0;
                width: 100%;
                height: 82;

                @media only screen and (max-width: 600px) {
                    height: 88%;
                    object-fit: cover;
                }
            }
        }
    }
}

.ThreeImg--Background {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media only screen and (max-width: 600px) {
        display: flex;
        min-height: 600px;
    }
    picture {
        img {
            display: flex !important;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media only screen and (max-width: 600px) {
            &:nth-child(1) {
                display: none;
            }
            &:nth-child(2) {
                display: none;
            }
        }
    }
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #000 -10.71%, rgba(10, 9, 9, 0) 77.23%);

        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
    .Content--Area {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 1512px;
        @media only screen and (max-width: 600px) {
            top: 56%;
            display: flex;
            flex-direction: column;
        }
        h6 {
            text-align: center;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 85px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 110.5px */
            @media only screen and (max-width: 600px) {
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 44.2px */
            }
        }
        button {
            width: max-content;
            margin-top: 50px;
            border: none;
            display: flex;
            padding: 18.844px 71.7px 18.844px 65.953px;
            justify-content: center;
            align-items: center;
            border-radius: 1632.334px;
            background: #f25d0f;
            color: #fff;
            text-align: center;
            font-family: Roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 28.266px; /* 128.48% */
            margin: auto;
            @media only screen and (max-width: 600px) {
                padding: 14px 54px;
                font-size: 18px;
                margin-top: 20px;
            }
        }
    }
}

.Reviews--Area {
    position: relative;
    top: -10px;
    padding: 100px 0;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
        padding: 50px 20px;
    }
    h6 {
        color: #fff;
        font-family: Roboto;
        font-size: 78px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 101.4px */
        text-align: center;
        position: relative;
        @media only screen and (max-width: 600px) {
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 33.8px */
        }
    }
    & > img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
    }
    .swiper {
        width: 100%;
        height: 65vh;
        @media only screen and (max-width: 600px) {
            min-height: max-content;
            height: 100%;
            margin-top: 30px;
            padding-bottom: 50px;
        }
        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
            display: flex;
            padding: 0 100px;
            @media only screen and (max-width: 600px) {
                display: none;
            }
        }
        .swiper-pagination-bullet-active {
            width: 40px;
            border-radius: 5px;
            background: #701a75 !important;
        }
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: transparent;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .swiper {
        margin-left: auto;
        margin-right: auto;
    }
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }

    .swiper-button-prev {
        @media only screen and (max-width: 600px) {
            background-image: url("../images/arrow-left-pink.svg");
            background-size: contain;
            background-position-x: center;
            background-repeat: no-repeat;
        }
    }

    .swiper-button-next {
        @media only screen and (max-width: 600px) {
            background-image: url("../images/arrow-right-pink.svg");
            background-size: contain;
            background-position-x: center;
            background-repeat: no-repeat;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 50px;
        height: 50px;
        //display: block !important;
        //background-color: #000;
        border-radius: 50%;
        @media only screen and (max-width: 600px) {
            display: flex !important;
            width: 36px;
            height: 36px;
            background-color: #ffffff;
            top: 97.5%;
        }
        &::after {
            font-size: 1.15rem;
            color: #fff;
            @media only screen and (max-width: 600px) {
                content: "";
            }
        }
    }
    .swiper-button-prev {
        @media only screen and (max-width: 600px) {
            left: 56%;
        }
    }

    .swiper--content {
        padding: 0 100px;
        display: flex;
        align-items: center;
        gap: 50px;
        @media only screen and (max-width: 600px) {
            padding: 0;
            flex-direction: column;
        }
        .Desc--Section {
            width: 90%;
            text-align: left;

            p,
            div {
                color: #fff;
                font-family: Roboto;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%; /* 51.2px */
                @media only screen and (max-width: 600px) {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 28.8px */
                }
            }
            div {
                font-weight: 400;
                margin-top: 20px;
                line-height: 120%;
            }
        }
        .Img--Section {
            position: relative;
            height: 500px;
            width: 100%;
            padding: 50px;
            @media only screen and (max-width: 600px) {
                height: auto;
                padding: 0;
            }
            & > div {
                position: absolute;
                width: 80%;
                height: 80%;
                border-radius: 52.364px;
                background: #541cf5;
                top: 50%;
                left: 50%;
                transform: rotate(-4deg) translate(-50%, -57%);
                max-width: 700px;
                margin: auto;
                @media only screen and (max-width: 600px) {
                    display: none;
                }
            }
            .quote {
                width: 60px;
                object-fit: contain;
                position: absolute;
                top: -7px;
                right: 14%;
                height: auto;
                @media only screen and (max-width: 600px) {
                    display: none;
                }
            }
            img {
                position: relative;
                z-index: 1;
                max-width: 700px;
                margin: auto;
            }
            svg {
                position: absolute;
                z-index: 10;
                bottom: -80px;
                left: 30px;

                @media only screen and (max-width: 600px) {
                    bottom: -172px;
                    left: -4px;
                    width: 100px;
                }
            }
        }
    }
}
