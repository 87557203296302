.nav-tabs .nav-link.active {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #1b8bcf !important;
  border-color: #fff;
  border-bottom-color: #1b8bcf !important;
  border-bottom-width: 3px !important;
}

.nav-tabs .nav-link {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #798287 !important;
  white-space: nowrap !important;
}

.form-check {
  display: flex;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  align-items: center !important;
  gap: 5px;
}
