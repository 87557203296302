.SessionNotes--Dialog {
  .MuiDialog-paper {
    max-height: 600px;
    max-width: 800px;
    overflow: auto;
    .Dialog--Content {
      &::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #878787; /* Color of the scrollbar handle */
        border-radius: 4px; /* Border radius of the scrollbar handle */
      }
    }

    .MuiFormGroup-root{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
