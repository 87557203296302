// body{
//   overflow-y: scroll !important;
// }

$fade-out-duration: 0.5s; // Adjust the duration as needed

// Keyframes for the fade-out animation
@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.SelectedFace {
  border: 3px solid #541cf5 !important;
}
.fade-out {
  opacity: 0;
  animation: fadeOutAnimation $fade-out-duration ease-out;
}

.fade-in {
  opacity: 1;
  animation: fadeInAnimation $fade-out-duration ease-in;
}

.min-h-screen {
  min-height: calc(100vh - 68px);
  position: relative;
}
.stella-screen {
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(185, 163, 248, 0.26) 0.13%,
    rgba(185, 163, 248, 0) 100%
  );
  @media only screen and (max-width: 600px) {
    position: fixed;
    top: 0;
    left: 0;
    padding-bottom: 0 !important;
    width: 100%;
    height: 100vh;
  }
  .IntroSection {
    position: relative;
    //margin: auto;
    &.fade-out-entering,
    &.fade-out-exiting {
      opacity: 1; // Initial opacity when entering or exiting
    }
    &.fade-out-entered {
      opacity: 0; // Final opacity when entered (invisible)
    }
    img {
      @media only screen and (max-width: 600px) {
        width: 60px;
        height: 60px;
      }
    }

    @media only screen and (max-width: 600px) {
      padding-top: 0 !important;
      padding-bottom: 10px !important;
      margin-top: 20px !important;
    }

    .pingContainer,
    .pingDot {
      height: 1.25rem !important;
      width: 1.25rem !important;
    }
    .pingContainer {
      @media only screen and (max-width: 600px) {
        right: 5px;
      }
    }
  }
  h1 {
    color: #000;
    text-align: center;
    font-family: Helvetica;
    font-size: 85px;
    font-style: normal;
    font-weight: 700;
    line-height: 96px; /* 112.941% */
    letter-spacing: -0.5px;
    @media only screen and (max-width: 600px) {
      font-size: 35px;
      text-align: left;
      line-height: 1rem;
    }
  }
  .FacesList--Container {
    @media only screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      gap: 7px;
      padding: 0 30px 0px 10px;
      width: 100vw;
      overflow-x: auto;
    }

    div {
      @media only screen and (max-width: 600px) {
        //overflow-x: auto;
        gap: 10px !important;
        margin-top: 0 !important;
        button {
          aspect-ratio: 1/1;
          min-height: 55px;
          min-width: 55px;
          img {
            aspect-ratio: 1/1;
            height: 55px;
            width: 55px;
          }
        }
      }
    }
  }

  h6 {
    color: #413d45;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    text-align: center;
    @media only screen and (max-width: 600px) {
      font-size: 21px;
      min-width: 139px;
      text-align: left;
    }
  }

  .pingContainer {
    position: relative;
    display: flex;
    height: 16px;
    width: 16px;

    .animate-ping {
      position: absolute;
      display: inline-flex;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: #97df8c;
      animation: ping 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
    }

    .pingDot {
      position: relative;
      display: inline-flex;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      background-color: #57b049;
    }
  }

  .chat-box {
    max-width: 1280px;
    width: 95%;
    height: 90%;
    box-shadow: 3px 20px 13px -1px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 10;
    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      border-radius: 0;
    }
    .chat-box--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      //background-color: #c4e5f9;
      background-color: #541cf5;
      padding: 15px 20px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 84px;
      @media only screen and (max-width: 600px) {
        padding: 6.5px 12px 6.5px 0px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .StellaAvatar--Selector {
        position: relative;
        img {
          border: 1.545px solid #f7f7f7;
          @media only screen and (max-width: 600px) {
            width: 43px;
            height: 43px;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          position: absolute;
          bottom: 4px;
          right: 4px;
        }
      }
      .LanguageSelector {
        p {
          text-transform: capitalize;
          color: #fff;
          font-family: Inter;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 42.063px; /* 191.198% */
        }
      }

      .chat-box--logo {
        flex: 1;

        p {
          color: #fff;
          text-align: center;
          font-family: Helvetica;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 369.231% */
          letter-spacing: -0.71px;
          text-align: center;
          margin-bottom: 0 !important;
          @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            text-align: left;
          }
        }
      }
      button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        color: #fff;

        & > svg {
          width: 20px;
          @media only screen and (max-width: 600px) {
            width: 25px;
          }
          //fill: #FFF;
        }
      }
      .LanguageDropdown {
        @media only screen and (max-width: 600px) {
          p,
          .DropDownIcon {
            display: none;
          }
        }
      }
    }

    .chat-box--content {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: calc(100% - 67px);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      background-position-y: center;
      background-size: cover !important;
      background-blend-mode: hard-light;
      position: relative;
      padding-bottom: 70px;
      @media only screen and (max-width: 600px) {
        flex: 1;
        // max-height: calc(100vh - 220px);
        //min-height: calc(100vh - 220px);
        height: 100%;
      }

      .space-y-5 {
        & > * + * {
          margin-top: 1rem;
        }
      }
      .chat-content--buttonArea {
        //margin-left: auto;
        padding: 10px;
        margin: -10px -10px 10px -10px;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;

        .chat-box--logo {
          p {
            color: #541cf5;
            font-family: Inter;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 42.063px; /* 175.265% */
          }
        }
        button {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 5px 8px;
          //background: #ededed;
          font-size: 14px;
          text-transform: capitalize;
          min-width: max-content;
          svg {
            width: 28px;
            height: 28px;
            color: #541cf5;
          }
          p {
            margin-bottom: 0 !important;
          }
        }
      }

      .chat-content--chats {
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        @media only screen and (max-width: 600px) {
          padding-bottom: 100px;
        }
        & > * + * {
          margin-top: 1.2rem;
        }

        & > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        &::-webkit-scrollbar {
          width: 4px; /* Width of the scrollbar */
        }
        &::-webkit-scrollbar-thumb {
          background-color: #e1e1e1; /* Color of the scrollbar handle */
          border-radius: 4px; /* Border radius of the scrollbar handle */
        }
        .response-area {
          display: flex;
          align-items: flex-start;
          flex-direction: revert;
          gap: 10px;
        }
        .avatar {
          position: relative;
          img {
            min-width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center top;
            aspect-ratio: 1/1;
          }
          span {
            width: 12px;
            height: 12px;
            display: flex;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #22c55e;
            border-radius: 50%;
            border: 1px solid #fff;
          }
        }
        .response--container {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .response {
          padding: 12px 15px;
          border-radius: 20px;
          width: max-content;
          max-width: 589px;
          font-weight: 500;
          font-size: 16px;
          color: #0e0f0c;
          word-wrap: break-word;
          @media only screen and (max-width: 600px) {
            max-width: 270px;
          }
        }
        .server-response {
          background-color: #fff;
          border: 1.545px solid var(#541cf530, rgba(84, 28, 245, 0.19));
          display: flex;
          flex-direction: column;
          gap: 10px;
          border-bottom-left-radius: 0;

          .audio-player {
            //margin: -12px -15px;
            @media only screen and (max-width: 600px) {
              width: 230px;
            }

            &::-webkit-media-controls-panel {
              background-color: #fff !important;
            }

            &::-webkit-media-controls-panel {
              background-color: #541cf5; /* Change the background color of the controls for WebKit browsers (e.g., Chrome, Safari) */
            }

            &::-moz-media-controls-panel {
              background-color: #541cf5 !important; /* Change the background color of the controls for Mozilla Firefox */
            }

            &::-ms-media-controls {
              background-color: #541cf5 !important; /* Change the background color of the controls for Microsoft Edge */
            }

            /* Optional: Customize the color of the progress bar */
            &::-webkit-media-slider-container {
              background-color: #541cf5 !important; /* Change the background color of the progress bar for WebKit browsers (e.g., Chrome, Safari) */
            }

            &::-moz-media-slider {
              background-color: #541cf5 !important; /* Change the background color of the progress bar for Mozilla Firefox */
            }

            &::-ms-media-slider {
              background-color: #541cf5 !important; /* Change the background color of the progress bar for Microsoft Edge */
            }
          }
        }
        .response-buttons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 10px;

          .button--item {
            border-radius: 20px;
            padding: 5px 16px;
            width: fit-content;
            border: 1px solid #541cf5;
            cursor: pointer;
            color: #541cf5;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 19.2px */
          }
        }
        .my-response {
          border: 1.457px solid var(--purple-stroke, rgba(84, 28, 245, 0.19));
          background: linear-gradient(
              334deg,
              rgba(84, 28, 245, 0.2) 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
              314.45% 139.15% at 3.59% 3.24%,
              rgba(255, 255, 255, 0.49) 0%,
              rgba(255, 255, 255, 0.07) 100%
            );
          color: #541cf5;
          margin-left: auto;
          border-bottom-right-radius: 0;

          .audio-player {
            //margin: -12px -15px;

            @media only screen and (max-width: 600px) {
              width: 230px;
            }
            &::-webkit-media-controls-panel {
              background-color: transparent !important;
            }
          }
        }

        .contains--explore-more {
          display: flex;
          flex-direction: column;
          width: max-content;
          .response {
            max-width: 100%;
          }
          .explore-btn {
            border: 1px solid;
            background-color: #fff;
            border-radius: 20px;
            padding: 6px 12px;
            font-size: 0.8em;
            font-weight: 500;
            margin: 15px auto;
            width: max-content;
          }
        }
      }

      .chat-content--input {
        display: flex;
        align-items: center;
        border-radius: 7px;
        //padding-bottom: 20px;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        padding: 10px;
        background: #fff0;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        backdrop-filter: blur(23px);
        @media only screen and (max-width: 600px) {
          position: fixed;
        }
        button {
          margin: 0;
          border: none;
          //color: #fff;
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          width: 102px;
          @media only screen and (max-width: 600px) {
            width: 86px;
          }
          //aspect-ratio: 1/1;
          box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.17);
          svg {
            width: 20px;
          }
        }

        .send-input--button {
          background-color: #fff;
          aspect-ratio: 1/1;
          border-radius: 50%;
          min-width: fit-content;
          width: max-content;
          margin-left: 8px;
          padding: 11px;
          svg {
            width: 31px;
            color: #666;
          }
        }
        .send-input--stop,
        .send-input--mic {
          border-radius: 50%;
          background-color: transparent;
          color: #666;
          width: auto;
          aspect-ratio: 1/1;
          box-shadow: none;
          margin-left: 10px;
          svg {
            color: #666;
            width: 30px;
          }
        }

        input {
          width: 100%;
          border: 0;
          padding: 13.5px;
          background: #fff;
          border-radius: 8px;
          //margin-right: 10px;
        }
      }

      .chat-content--emotions {
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        .emotion-item {
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          flex-basis: calc(
            25% - 12px
          ); /* Set the initial width of each item to occupy 25% of the container */
          margin: 6px;
          text-align: center;
          background-color: #541cf5;
          padding: 15px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 0.85rem;
          //font-weight: 500;
          min-height: 80px;
          @media only screen and (max-width: 600px) {
            flex-basis: calc(50% - 12px);
          }
        }

        @media only screen and (max-width: 600px) {
          .emotion-item:last-child {
            flex-grow: 1; /* Allow the last item to take up the remaining space */
          }
        }
      }
    }
  }

  .dropdown-toggle::after {
    display: none !important;
  }
  .dropdown-toggle {
    display: none !important;
  }
  .dropdown-menu.show {
    transform: translate(calc(-100% + 7px), 0px) !important;
    padding: 10px 5px;
    max-height: 355px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px; /* Width of the scrollbar */
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e1e1e1; /* Color of the scrollbar handle */
      border-radius: 4px; /* Border radius of the scrollbar handle */
    }
    p {
      font-weight: 500;
    }
    a,
    p {
      margin-bottom: 0 !important;
      font-size: 0.85em !important;
      font-weight: 500;
      padding: 5px 10px !important;
      &:hover {
        border-radius: 15px;
      }
    }
    .selected-language {
      background-color: #c4e5f9;
      border-radius: 15px;
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 9px;
    margin: -13px auto;
    text-align: center;

    div {
      width: 9px;
      height: 9px;
      background-color: #fff;
      border-radius: 100%;
      display: inline-block;
      animation: bouncedelay 1.4s infinite ease-in-out;

      &:nth-child(1) {
        animation-delay: -0.32s;
      }
      &:nth-child(2) {
        animation-delay: -0.16s;
      }
    }

    @keyframes bouncedelay {
      0%,
      80%,
      100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1);
      }
    }
  }

  #loading-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 37.6px;
    min-width: 73px;
    border-radius: 10px;
    position: relative;
    background-color: #e3e3e4;
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.FaceSelector--Dialog {
  .Dialog--Top {
    @media only screen and (max-width: 600px) {
      background-color: #413d45;
      width: 70px;
      height: 5px;
      margin: 10px auto 0;
      border-radius: 10px;
    }
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: max-content;
    max-width: 1000px;
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin: 0;
      margin-top: auto;
      border-radius: 25px 25px 0 0 !important;
    }

    .MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
      @media only screen and (max-width: 600px) {
        padding: 0;
        .Avatar--Container {
          gap: 10px !important;
          overflow-x: auto;
          padding: 0 20px 20px 20px !important;
          display: grid !important;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          button {
            min-width: 80px;
            width: 80px;
            height: 80px;
            img {
              min-width: 80px !important;
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
}
